import Player from '@vimeo/player';

const sections = document.querySelectorAll('.text-image');
if (sections.length > 0) {
    const closePopup = (popupVideoElement, videoElement, player) => {
        if (!popupVideoElement) return;
        popupVideoElement.classList.remove('-active');

        setTimeout(() => {
            player.destroy();
            videoElement.innerHTML = '';
        }, 300);
    };

    const handleVideoButtonClick = (e, player, popupVideoElement) => {
        const { target } = e;
        target.classList.add('-block');
        const dataVimeo = target.getAttribute('data-vimeo-video-id');
        const videoElement = popupVideoElement.querySelector(
            '.popup-video__video'
        );
        const popupGradient = popupVideoElement.querySelector(
            '.popup-video__gradient'
        );
        const closePopupButton = popupVideoElement.querySelector(
            '.popup-video__close'
        );
        const loader = target.querySelector('.loader');
        if (!dataVimeo) return;

        loader.classList.add('-active');

        player = new Player(videoElement, {
            id: dataVimeo,
            responsive: true,
        });

        player.on('loaded', function () {
            popupVideoElement.classList.add('-active');
            target.classList.remove('-block');
            popupGradient.addEventListener('click', () =>
                closePopup(popupVideoElement, videoElement, player)
            );
            closePopupButton.addEventListener('click', () =>
                closePopup(popupVideoElement, videoElement, player)
            );
            closePopupButton.addEventListener('keydown', e => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    closePopup(popupVideoElement, videoElement, player);
                }
            });
            loader.classList.remove('-active');

            player.on('play', function () {
                popupVideoElement.classList.add('-playing');
            });
            player.on('pause', function () {
                popupVideoElement.classList.remove('-playing');
            });
        });
    };

    const handleImageHeight = section => {
        if (!section) return;

        const image = section.querySelector('.text-image__image-wrapper img');
        if (!image) return;

        if (window.innerWidth > 991.98 && window.innerWidth < 1680.98) {
            if (!image.style.height) {
                image.style.height = `${image.offsetHeight * 0.7}px`;
            }
        } else {
            image.style.removeProperty('height');
        }
    };

    sections.forEach(section => {
        const videoButton = section.querySelector('[data-vimeo-video-id]');
        const popupVideo = section.querySelector('.popup-video');
        let player = {};
        if (videoButton) {
            videoButton.addEventListener('click', e =>
                handleVideoButtonClick(e, player, popupVideo)
            );
        }

        // handleImageHeight(section);
        // window.addEventListener("resize", (e) => {
        //     handleImageHeight(section);
        // })
    });
}
